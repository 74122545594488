/* eslint-disable no-useless-escape */
import React from 'react';
import { CreateFlowContainer, ICreateFlowContainerProps } from 'components/CreateFlow';
import { Details, Locations, Manage, Billing } from './Steps';
import CustomersContext from './CustomersContext';
import { ECustomerFlowSteps, ECustomerFlowLabels } from './interface';
import { InlineNotification } from 'carbon-components-react';
import { Loader } from 'components/Loader';
import { RegexFormat } from 'utils/common';

export interface ICustomerEditProps {
	checkCustomerLocationOTP: (customerId: string) => boolean;
	handleCreateFlowView: (hide: boolean, mode: string) => void;
	hasCustomerDataChanged: () => boolean;
	handleErrorMessage: (msg: string) => void;
	loaderState: boolean;
	updateCustomerDetails: () => void;
}

export interface ICustomerEditState {
	customerOTPValidated: boolean;
	currentStep: number;
	customerServiceEmail: string;
	isServiceEmailInvalid: boolean;
}

const addProgressStepLabels = Object.values(ECustomerFlowLabels);

export default class CustomerEdit extends React.Component<ICustomerEditProps, ICustomerEditState> {
	static contextType = CustomersContext;

	constructor(props: ICustomerEditProps) {
		super(props);

		this.state = {
			customerOTPValidated: false,
			isServiceEmailInvalid: false,
			currentStep: ECustomerFlowSteps.DETAILS,
			customerServiceEmail: ''
		};
	}

	componentDidMount() {
		this.setState({
			customerServiceEmail: this.context.details.serviceContactEmail
		})
	}


	checkCustomerLocationOTPStatus = async() => {
		const hasValidated: boolean = await this.props.checkCustomerLocationOTP(this.context.details.id);
		this.setState({
			customerOTPValidated : hasValidated
		});
	}

	handleProgressStep = (step: number) => {
		let nextStep: number = step;
		if (step === this.state.currentStep) {
			nextStep = step + 1;
			this.setState({
				currentStep: nextStep
			});
		} else {
			this.setState({
				currentStep: step
			});
		}
	};

	isLastStep = (): boolean => {
		return this.state.currentStep === addProgressStepLabels.length - 1;
	}

	shouldEnableSave = () => {
		let buttonDisabled: boolean = true;

		if(this.isLastStep()){
			if(this.props.hasCustomerDataChanged()){
				if(this.state.isServiceEmailInvalid){
					return buttonDisabled;
				}
				return this.validateSteps();
			}
			return buttonDisabled;
		}
		return buttonDisabled = false;
	}

	handleServiceEmailInvalid = (isValid: boolean) => {
		this.setState({
			isServiceEmailInvalid: isValid
		})
	}

	validateSteps = (): boolean => {
		const customerDetails = this.context.details;
		if(
			customerDetails.companyName === '' ||
			customerDetails.contactEmail === '' ||
			!customerDetails.contactEmail.match(RegexFormat.email) ||
			customerDetails.contactName === '' ||
			customerDetails.contactPhoneNumber === '' ||
			!customerDetails.contactPhoneNumber.match(RegexFormat.phoneNumber) ||
			customerDetails.logo === '' ||
			customerDetails.address.addressLine1 === '' ||
			customerDetails.address.city === '' ||
			customerDetails.address.postcode === '' ||
			!customerDetails.address.postcode.match(RegexFormat.postCode) ||
			customerDetails.address.state === '' ||
			customerDetails.serviceContactEmail === '' ||
			!customerDetails.serviceContactEmail.match(RegexFormat.email) ||
			customerDetails.paymentEmail === '' ||
			!customerDetails.paymentEmail.match(RegexFormat.email) ||
			customerDetails.serviceContactName === '' ||
			customerDetails.serviceContactPhone === '' ||
			customerDetails.billing === 'Monthly' || customerDetails.billing === 'Annually' ||
			!customerDetails.serviceContactPhone.trim().match(RegexFormat.phoneNumber)
		){
			return true
		}
		return false;
	}



	public render() {
		return (
			<React.Fragment>
				{this.props.loaderState && (<Loader message={'Updating customer details...'}/>)}
				<CreateFlowContainer
					{...{
						title: 'Edit Customer',
						currentStep: this.state.currentStep,
						handleProgressStep: this.handleProgressStep,
						handleCreateFlowView: this.props.handleCreateFlowView,
						isLastStep: this.isLastStep,
						mode: 'edit',
						shouldEnableSave: this.shouldEnableSave(),
						stepsType: addProgressStepLabels,
						updateCustomer: this.props.updateCustomerDetails,
						renderMain: () => (
							<React.Fragment>
								{this.state.currentStep === 0 && <Details />}
								{this.state.currentStep === 1 && <Locations />}
								{this.state.currentStep === 2 &&(
								<Manage {...{
										handleServiceEmailInvalid: this.handleServiceEmailInvalid,
										serviceEmailInvalid: this.state.isServiceEmailInvalid,
										currentServiceContactEmail: this.state.customerServiceEmail
									}}
								/>)}
								{this.state.currentStep === 3 && <Billing />}
								{this.context.errorMessage ? (
									<InlineNotification {...{
										notificationType: 'inline',
										statusIconDescription: '',
										onCloseButtonClick: () => this.props.handleErrorMessage(''),
										kind: 'warning',
										title: this.context.errorMessage
									}}/>
								): null}
							</React.Fragment>
						)
					} as ICreateFlowContainerProps}
				/>
			</React.Fragment>
		);
	}
}
