import { Button, ProgressIndicator, ProgressStep } from '@carbon/ibm-security';
import { EMode } from 'modules/Customers/Users/UserInterfaces';
import { ELeadEditLabels } from 'modules/Leads/LeadsInterface';
import React from 'react';

const PREFIX = 'sc--createflow';
export interface ICreateFlowContainerProps {
	createNewEntry?: () => void;
	currentStep: number;
	handleSubmit?: () => void;

	handleProgressStep: (step: number) => void;
	handleCreateFlowView: (hide: boolean, mode: string) => void;
	isLastStep: () => boolean;
	mode: string;
	renderMain: () => void;
	shouldEnableSave: boolean;
	stepsType: string[];
	title: string;
	updateCustomer?: () => void;
	updateContractor?: () => void;
	leadHandlerFunction?: () => void;
}

const buttonLabels = {
	CREATE_FLOW_SAVE: 'Save',
	CREATE_FLOW_NEXT: 'Next',
	CREATE_FLOW_CANCEL: 'Cancel',
	CREATE_FLOW_CONVERT: 'Convert lead'
};
class CreateFlowContainer extends React.Component<ICreateFlowContainerProps, any> {
	handleSubmit = () => {
		if (this.props.isLastStep()) {
			if (this.props.mode === 'add') {
				if (this.props.createNewEntry) {
					return this.props.createNewEntry();
				}
			} else {
				if (this.props.updateCustomer) {
					return this.props.updateCustomer();
				}
				if(this.props.updateContractor){
					return this.props.updateContractor();
				}
				if(this.props.leadHandlerFunction){
					return this.props.leadHandlerFunction();
				}
				this.props.handleSubmit();
			}
			return;
		}
		return this.props.handleProgressStep(this.props.currentStep);
	};

	isStepsDisabled = (index: number): boolean => {
		if ([EMode.EDIT, 'edit'].includes(this.props.mode)) {
			return false;
		}
		return index > this.props.currentStep;
	};

	handlePrimaryButton = () => {
		if(this.props.isLastStep()){
			if(this.props.stepsType[0] === ELeadEditLabels.Convert){
				return buttonLabels.CREATE_FLOW_CONVERT;
			}
			return buttonLabels.CREATE_FLOW_SAVE;
		} else {
			return buttonLabels.CREATE_FLOW_NEXT;
		}
	}

	render() {

		return (
			<React.Fragment>
				<div className={`${PREFIX} bx--grid`}>
					<div className={`${PREFIX}-intro`}>
						<div>
							<h2>{this.props.title}</h2>
						</div>
						<div className={`${PREFIX}-button-wrapper`}>
							<Button
								kind='secondary'
								onClick={() => this.props.handleCreateFlowView(false, this.props.mode)}
								disabled={false}>
								{buttonLabels.CREATE_FLOW_CANCEL}
							</Button>
							<Button
								kind='primary'
								onClick={() => this.handleSubmit()}
								disabled={this.props.shouldEnableSave}>
								{this.handlePrimaryButton()}
							</Button>
						</div>
					</div>
					<div className={`${PREFIX}-progress-indicator-wrapper`}>
						<ProgressIndicator
							currentIndex={this.props.currentStep}
							className={`${PREFIX}-progress-indicator`}
							onChange={this.props.handleProgressStep}>
							{this.props.stepsType.map((label: string, index: number) => (
								<ProgressStep
									index={index}
									className='sc--progress-step'
									current={this.props.currentStep === index}
									key={`${label}-${index}`}
									label={label}
									disabled={this.isStepsDisabled(index)}
								/>
							))}
						</ProgressIndicator>
					</div>
					{this.props.renderMain()}
				</div>
			</React.Fragment>
		);
	}
}

export { CreateFlowContainer };
