import React from "react";
import Add16 from "@carbon/icons-react/es/add/16";
import Edit16 from "@carbon/icons-react/es/edit/16";
import TrashCan16 from "@carbon/icons-react/es/trash-can/16";
import DataTable from "carbon-components-react/lib/components/DataTable";
import Pagination from "@carbon/ibm-security/lib/components/Pagination";
import DataTableSkeleton from "carbon-components-react/lib/components/DataTableSkeleton";
import Button from "carbon-components-react/lib/components/Button";
import { Modal } from "@carbon/ibm-security";
import { IContractor, ETableHeaderMap } from "./interface";
import {
  getItemRangeText,
  getPageRangeText,
  ITableHeaderItem,
  IRow,
  ICell,
} from "utils/table";
import { IPagination } from "interfaces/Pagination";
import { PageSizes } from "utils/table";

const {
  Table,
  TableContainer,
  TableHead,
  TableHeader,
  TableBody,
  TableCell,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} = DataTable;
const headers: ITableHeaderItem[] = [
  {
    header: ETableHeaderMap.NAME,
    key: "companyName",
  },
  {
    header: ETableHeaderMap.CUSTOMER,
    key: "customerName",
  },
  {
    header: ETableHeaderMap.PHONE,
    key: "contactPhoneNumber",
  },
  {
    header: ETableHeaderMap.EMAIL,
    key: "contactEmail",
  },
  {
    header: "",
    key: ETableHeaderMap.EDIT,
  },
];

interface IContractorsTableProps {
  rows: IContractor[];
  loaded: boolean;
  handleCreateFlowView: (show: boolean, mode: string) => void;
  handleTableSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setEditableContractor: (customer: IContractor) => void;
  handleDeleteContractor: (contractor: any) => void;
}

interface IContractorsTableState {
  enableTearsheet: boolean;
  pagination: IPagination;
  searchInProgress: boolean;
  isModalOpen: boolean;
  selectedContractorId: string;
}

class ContractorsTable extends React.Component<
  IContractorsTableProps,
  IContractorsTableState
> {
  constructor(props: IContractorsTableProps) {
    super(props);
    this.state = {
      enableTearsheet: false,
      pagination: {
        pageSize: PageSizes.pageSizes[0],
        page: 1,
      },
      searchInProgress: false,
      isModalOpen: false,
      selectedContractorId: "",
    };
  }

  editContractorById = (id: string) => {
    const customers: IContractor[] = this.props.rows;
    const customer = customers.find((customerObj: IContractor) => {
      return customerObj.id === id;
    });
    this.props.setEditableContractor(customer as IContractor);
  };

  filterRows = (rows: IContractor[]) => {
    const startIndex =
      (this.state.pagination.page - 1) * this.state.pagination.pageSize;
    const endIndex =
      this.state.pagination.page * this.state.pagination.pageSize;
    return rows.filter((row: IContractor, index: number) => {
      if (index >= startIndex && index < endIndex) return true;
      return false;
    });
  };

  handlePageChange = (pageChange: { page: number; pageSize: number }): void => {
    this.setState((prevState) => {
      return {
        pagination: {
          ...prevState.pagination,
          page: pageChange.page,
          pageSize: pageChange.pageSize,
        },
      };
    });
  };

  closeFlowView = async () => {
    const result: any = await this.props.handleDeleteContractor(
      `${this.state.selectedContractorId}`
    );
    this.setState({ isModalOpen: false });
  };

  renderDangerModal = (): JSX.Element => {
    return (
      <Modal
        className={`sc--modal-danger`}
        danger={true}
        open={this.state.isModalOpen}
        iconDescription={"Close the modal"}
        primaryButtonText={"Cancel"}
        secondaryButtonText={"Delete"}
        modalHeading={"Are you sure you want to delete this item?"}
        shouldSubmitOnEnter={true}
        onRequestClose={() => this.setState({ isModalOpen: false })}
        onRequestSubmit={() => this.setState({ isModalOpen: false })}
        onSecondarySubmit={() => this.closeFlowView()}
      >
        <div>
          <p className="sc--composed-modal--body">
            {
              "This action cannot be undone. If you wish to permanently delete this item, click 'Delete'. To keep it, click 'Cancel'."
            }
          </p>
        </div>
      </Modal>
    );
  };

  render() {
    const filteredRows = this.filterRows(this.props.rows);
    return (
      <React.Fragment>
        {this.renderDangerModal()}
        <div className={"sc--table-wrapper bx--data-table--zebra"}>
          <DataTable
            rows={filteredRows}
            isSortable={true}
            headers={headers}
            render={({ rows, headers, getHeaderProps }) => (
              <TableContainer>
                <TableToolbar>
                  <TableToolbarContent>
                    <TableToolbarSearch
                      className="sc--search-input"
                      persistent={true}
                      placeholder={"Filter by name"}
                      onChange={this.props.handleTableSearch}
                    />
                    <Button
                      kind={"primary"}
                      renderIcon={Add16}
                      iconDescription={"Add contractor"}
                      onClick={() =>
                        this.props.handleCreateFlowView(true, "add")
                      }
                    >
                      Add Contractor
                    </Button>
                  </TableToolbarContent>
                </TableToolbar>
                {this.props.loaded ? (
                  <React.Fragment>
                    <Table>
                      <TableHead className={"sc--table-header"}>
                        <TableRow>
                          {headers.map((header: ITableHeaderItem) => (
                            <TableHeader
                              {...getHeaderProps({ header })}
                              key={header.key}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row: IRow) => (
                          <TableRow key={row.id} className={"sc--table-row"}>
                            {row.cells.map((cell: ICell) => {
                              if (cell.info.header === "Edit") {
                                return (
                                  <TableCell key={cell.id}>
                                    <div className="d-flex">
                                      <Button
                                        className={"sc--edit-table-icon"}
                                        kind={"ghost"}
                                        renderIcon={Edit16}
                                        iconDescription={"Edit contractor"}
                                        onClick={() => {
                                          this.editContractorById(row.id);
                                        }}
                                      />
                                      <Button
                                        className={"sc--edit-table-icon"}
                                        kind={"ghost"}
                                        renderIcon={TrashCan16}
                                        iconDescription={"Delete contractor"}
                                        onClick={() => {
                                          this.setState({
                                            isModalOpen: true,
                                            selectedContractorId: row.id,
                                          });
                                          // this.editContractorById(row.id)
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      backwardText="Previous page"
                      disabled={false}
                      forwardText="Next page"
                      isLastPage={false}
                      itemRangeText={getItemRangeText}
                      itemsPerPageText="Items per page:"
                      onChange={this.handlePageChange}
                      page={this.state.pagination.page}
                      pageInputDisabled
                      pageNumberText="Page Number"
                      pageRangeText={getPageRangeText}
                      pageSize={this.state.pagination.pageSize}
                      pageSizes={PageSizes.pageSizes}
                      totalItems={this.props.rows.length}
                    />
                  </React.Fragment>
                ) : (
                  <DataTableSkeleton />
                )}
              </TableContainer>
            )}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default ContractorsTable;
