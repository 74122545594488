import React from "react";
import Add16 from "@carbon/icons-react/es/add/16";
import Edit16 from "@carbon/icons-react/es/edit/16";
import Popup from "@carbon/icons-react/es/popup/16";
import DataTable from "carbon-components-react/lib/components/DataTable";
import Pagination from "@carbon/ibm-security/lib/components/Pagination";
import DataTableSkeleton from "carbon-components-react/lib/components/DataTableSkeleton";
import Button from "carbon-components-react/lib/components/Button";
import { ICustomer, ETableHeaderMap, CustomerEditSteps } from "./interface";
import { OverflowMenu, OverflowMenuItem } from "@carbon/ibm-security";
import {
  getItemRangeText,
  getPageRangeText,
  ITableHeaderItem,
  IRow,
  ICell,
} from "utils/table";
import { IPagination } from "interfaces/Pagination";
import { PageSizes } from "utils/table";

const {
  Table,
  TableContainer,
  TableHead,
  TableHeader,
  TableBody,
  TableCell,
  TableRow,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} = DataTable;
const headers: ITableHeaderItem[] = [
  {
    header: ETableHeaderMap.NAME,
    key: "companyName",
  },
  {
    header: ETableHeaderMap.CONTACT,
    key: "contactName",
  },
  {
    header: ETableHeaderMap.PHONE,
    key: "contactPhoneNumber",
  },
  {
    header: ETableHeaderMap.EMAIL,
    key: "contactEmail",
  },
  {
    header: ETableHeaderMap.USER_COUNT,
    key: "usersCount",
  },
  {
    header: ETableHeaderMap.SERVICE_LOCATION_COUNT,
    key: "servicelocationCount",
  },
  {
    header: "",
    key: ETableHeaderMap.EDIT,
  },
];

interface ICustomersTableProps {
  rows: ICustomer[];
  loaded: boolean;
  handleCreateFlowView: (show: boolean, mode: string) => void;
  handleTableSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setEditableCustomer: (customer: ICustomer) => void;
  setCustomersUsers: (customer: any) => void;
}

interface ICustomersTableState {
  enableTearsheet: boolean;
  pagination: IPagination;
}

class CustomersTable extends React.Component<
  ICustomersTableProps,
  ICustomersTableState
> {
  constructor(props: ICustomersTableProps) {
    super(props);
    this.state = {
      enableTearsheet: false,
      pagination: {
        pageSize: PageSizes.pageSizes[0],
        page: 1,
      },
    };
  }

  editCustomerById = (id: string) => {
    const customers: ICustomer[] = this.props.rows;
    const customer = customers.find((customerObj: ICustomer) => {
      return customerObj.id === id;
    });

    this.props.setEditableCustomer(customer as ICustomer);
  };

  showCustomersByUsers = (data: { id: string; contactName: string }) => {
    this.props.setCustomersUsers(data);
    // this.props.handleCreateFlowView(true, "view")
  };

  filterRows = (rows: ICustomer[]) => {
    const startIndex =
      (this.state.pagination.page - 1) * this.state.pagination.pageSize;
    const endIndex =
      this.state.pagination.page * this.state.pagination.pageSize;
    return rows.filter((row: ICustomer, index: number) => {
      if (index >= startIndex && index < endIndex) return true;
      return false;
    });
  };

  handlePageChange = (pageChange: { page: number; pageSize: number }): void => {
    this.setState((prevState) => {
      return {
        pagination: {
          ...prevState.pagination,
          page: pageChange.page,
          pageSize: pageChange.pageSize,
        },
      };
    });
  };

  handleRecordEvents = (records: { event: string; row: any }): void => {
    switch (records.event) {
      case "Edit":
        this.editCustomerById(records.row.id);
        break;
      case "Users":
        this.showCustomersByUsers({
          id: records.row.id,
          contactName: records.row.cells[0].value,
        });
        this.props.handleCreateFlowView(true, "view-user");
        break;
      case "Locations":
        case "Users":
          this.showCustomersByUsers({
            id: records.row.id,
            contactName: records.row.cells[0].value,
          });
          this.props.handleCreateFlowView(true, "view-location");
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className={"sc--table-wrapper bx--data-table--zebra"}>
          <DataTable
            rows={this.props.rows}
            isSortable={true}
            headers={headers}
            render={({ rows, headers, getHeaderProps }) => (
              <TableContainer>
                <TableToolbar>
                  <TableToolbarContent>
                    <TableToolbarSearch
                      className="sc--search-input"
                      persistent={true}
                      placeholder={"Filter by name"}
                      onChange={this.props.handleTableSearch}
                    />
                    <Button
                      kind={"primary"}
                      renderIcon={Add16}
                      iconDescription={"Add new customer"}
                      onClick={() =>
                        this.props.handleCreateFlowView(true, "add")
                      }
                    >
                      Add Customer
                    </Button>
                  </TableToolbarContent>
                </TableToolbar>
                {this.props.loaded ? (
                  <React.Fragment>
                    <Table>
                      <TableHead className={"sc--table-header"}>
                        <TableRow>
                          {headers.map((header: ITableHeaderItem) => (
                            <TableHeader
                              {...getHeaderProps({ header })}
                              key={header.key}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row: IRow) => (
                          <TableRow key={row.id} className={"sc--table-row"}>
                            {row.cells.map((cell: ICell) => {
                              if (cell.info.header === "Edit") {
                                return (
                                  <TableCell key={cell.id}>
                                    <OverflowMenu flipped={true}>
                                      {
									  	Object.values(CustomerEditSteps).map((step, index: number): JSX.Element | undefined => {
                                          if (typeof step !== "number") {
                                            return (
                                              <OverflowMenuItem
                                                key={`${step}-menu-item`}
                                                itemText={step}
                                                onClick={() => {
                                                  this.handleRecordEvents({ event: step, row });
                                                }}
                                                title={step}
                                              />
                                            );
                                          }
                                          return;
                                        })
									  }
                                    </OverflowMenu>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                );
                              }
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      backwardText="Previous page"
                      disabled={false}
                      forwardText="Next page"
                      isLastPage={false}
                      itemRangeText={getItemRangeText}
                      itemsPerPageText="Items per page:"
                      onChange={this.handlePageChange}
                      page={this.state.pagination.page}
                      pageInputDisabled
                      pageNumberText="Page Number"
                      pageRangeText={getPageRangeText}
                      pageSize={this.state.pagination.pageSize}
                      pageSizes={PageSizes.pageSizes}
                      totalItems={this.props.rows.length}
                    />
                  </React.Fragment>
                ) : (
                  <DataTableSkeleton />
                )}
              </TableContainer>
            )}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default CustomersTable;
