/* eslint-disable no-useless-escape */
import React from 'react';
import { CreateFlowContainer, ICreateFlowContainerProps } from 'components/CreateFlow';
import { Details, Locations, Manage, Billing } from './Steps';
import { ICustomer, ICustomerLocations, ECustomerFlowLabels } from './interface';
import { EFileStatus, IFileProps } from 'utils/files';
import { Loader } from 'components/Loader';
import { RegexFormat } from 'utils/common';
export interface ICustomerAddProps {
	customerDetails: ICustomer;
	createNewCustomer: (customerLocationsArray: ICustomerLocations[]) => void;
	handleCreateFlowView: (hide: boolean, mode: string) => void;
	loaderState: boolean;
}

export interface ICustomerAddState {
	currentStep: number;
	customerLocationsArray: ICustomerLocations[];
	fileStatus: string;
	fileName: string;
	isOpen: boolean;
	logo: IFileProps;
	isServiceEmailInvalid: boolean;

}

const addProgressStepLabels = Object.values(ECustomerFlowLabels);


export default class CustomerAdd extends React.Component<ICustomerAddProps, ICustomerAddState> {
	constructor(props: ICustomerAddProps) {
		super(props);

		this.state = {
			currentStep: 0,
			isServiceEmailInvalid: false,
			customerLocationsArray: [],
			logo: {
				fileStatus: EFileStatus.UPLOADING,
				fileName: ''
			},
			fileStatus: EFileStatus.UPLOADING,
			fileName: '',
			isOpen: false
		};
	}

	createNewCustomer = () => {
		this.props.createNewCustomer(this.state.customerLocationsArray);
		this.setState({
			customerLocationsArray: []
		})
	}

	setCustomerLocationsArray = (parsedCSVtoJSONArray: ICustomerLocations[] = []) => {
		this.setState({
			customerLocationsArray: parsedCSVtoJSONArray
		})
	}

	handleProgressStep = (step: number) => {
		let nextStep: number = step;
		if (step === this.state.currentStep) {
			nextStep = step + 1;
			this.setState({
				currentStep: nextStep
			});
		} else {
			this.setState({
				currentStep: step
			});
		}
	};

	handleCSVFileState = (fileStatus: string) => {
		this.setState({
			fileStatus: fileStatus
		})
	}
	setFileName = (fileName: string) => {
		this.setState({
			fileName: fileName
		})
	}

	handleServiceEmailInvalid = (isValid: boolean) => {
		this.setState({
			isServiceEmailInvalid: isValid
		})
	}

	shouldEnableSave = (): boolean => {
		const customerDetails = this.props.customerDetails;
		const customerAddress = customerDetails.address;
		let disabled: boolean = true;

		switch(addProgressStepLabels[this.state.currentStep]) {
			case ECustomerFlowLabels.DETAILS:
				if (
					customerDetails.companyName === '' ||
					customerDetails.contactEmail === '' ||
					!customerDetails.contactEmail.match(RegexFormat.email) ||
					customerDetails.contactName === '' ||
					customerDetails.contactPhoneNumber === '' ||
					!customerDetails.contactPhoneNumber.match(RegexFormat.phoneNumber) ||
					customerDetails.logo === '' ||
					customerAddress.addressLine1 === '' ||
					customerAddress.city === '' ||
					customerAddress.postcode === '' ||
					!customerDetails.address.postcode.match(RegexFormat.postCode) ||
					customerAddress.state === ''
				) {
					disabled = true;
				} else {
					disabled = false
				}
				break;
			case ECustomerFlowLabels.LOCATIONS :
				if (this.state.customerLocationsArray.length > 0){
					disabled = false;
				} else {
					disabled = true;
				}
				break;
			case ECustomerFlowLabels.MANAGE :
				if (
					customerDetails.serviceContactEmail === '' ||
					!customerDetails.serviceContactEmail.match(RegexFormat.email) ||
					this.state.isServiceEmailInvalid ||
					customerDetails.paymentEmail === '' ||
					!customerDetails.paymentEmail.match(RegexFormat.email) ||
					customerDetails.serviceContactName === '' ) {
					disabled = true;
				} else {
					disabled = false;
				}
				break;
			case ECustomerFlowLabels.BILLING :
				disabled = false;
		}
		return disabled;
	};

	isLastStep = () => this.state.currentStep === addProgressStepLabels.length - 1;

	public render() {

		const detailsProps = {
			logo: this.state.logo
		}
		const locationStepProps = {
			handleCSVFileState: this.handleCSVFileState,
			fileName: this.state.fileName,
			fileStatus: this.state.fileStatus,
			setCustomerLocationsArray: this.setCustomerLocationsArray,
			setFileName: this.setFileName
		}

		const manageStepProps = {
			handleServiceEmailInvalid: this.handleServiceEmailInvalid,
			serviceEmailInvalid: this.state.isServiceEmailInvalid
		}

		return (
			<React.Fragment>
				{this.props.loaderState && (<Loader message={'Creating new customer...'}/>)}
					<CreateFlowContainer
					{...{
						title: 'Add new customer',
						createNewEntry: this.createNewCustomer,
						currentStep: this.state.currentStep,
						handleProgressStep: this.handleProgressStep,
						handleCreateFlowView: this.props.handleCreateFlowView,
						isLastStep: this.isLastStep,
						mode: 'add',
						shouldEnableSave: this.shouldEnableSave,
						stepsType: addProgressStepLabels,
						renderMain: () => (
							<React.Fragment>
								{this.state.currentStep === 0 && <Details {...detailsProps}/>}
								{this.state.currentStep === 1 && <Locations {...locationStepProps} />}
								{this.state.currentStep === 2 &&(<Manage {...manageStepProps} />)}
								{this.state.currentStep === 3 && <Billing />}
							</React.Fragment>
						)
					} as ICreateFlowContainerProps}
				/>
			</React.Fragment>
		);
	}
}
