import { Header, HeaderName } from 'carbon-components-react/lib/components/UIShell';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ContractorsContainer from './modules/Contractors/ContractorsContainer';
import { LeadsContainer } from './modules/Leads/LeadsContainer';
import CustomersContainer from './modules/Customers/CustomersContainer';
import LoginContainer from './modules/Login/LoginContainer';
import LandingPage from './modules/Landing/LandingContainer';
import PasswordReset from 'modules/Login/PasswordReset';
import * as ROUTES from './utils/routes';
import { AuthProvider } from './components/Auth';
import { withFirebase } from './components/Firebase';
import PrivateRoute from './components/Router/PrivateRoute';
import ActivityContainer from 'modules/Activity/ActivityContainer';
import { HeaderNavWrapper } from './components/HeaderNavWrapper';
import { NoMatch } from './components/Router';
import ManageContainer from 'modules/Customers/Users/UserContainer';

const App: React.FunctionComponent = () => {
	return (
		<AuthProvider>
			<Router>
				<Header className={'sc--header-wrapper'} aria-label='Simplyclik Admin'>
					<HeaderName href={`${ROUTES.ADMIN_DASHBOARD}`} prefix='Simplyclik'>{'Admin'}</HeaderName>
					<HeaderNavWrapper />
				</Header>
				<div className={'sc--main-wrapper bx--grid'}>
					<Switch>
						<Route exact path={'/'} component={LoginContainer} />
						<Route path={ROUTES.PASSWORD_RESET} component={PasswordReset} />
						<PrivateRoute exact path={ROUTES.ADMIN_DASHBOARD} component={LandingPage} />
						<PrivateRoute path={ROUTES.ADMIN_ACTIVITY} component={ActivityContainer} />
						<PrivateRoute path={ROUTES.ADMIN_CUSTOMERS} component={CustomersContainer} />
						<PrivateRoute path={ROUTES.CUSTOMERS_DETAILS_RECORDS} component={ManageContainer} />
						<PrivateRoute path={ROUTES.ADMIN_CONTRACTORS} component={ContractorsContainer} />
						<PrivateRoute exact path={ROUTES.ADMIN_LEADS} component={LeadsContainer} />
						<Route component={NoMatch} />
					</Switch>
				</div>
			</Router>
		</AuthProvider>
	);
}

export default withFirebase(App);
