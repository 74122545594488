/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { FirebaseContext } from "components/Firebase";
import { UserReducer } from "./UserReducer";
import ManageContext from "./UserContext";
import { UserTable } from "./UserTable";
import { AddEditUser } from "./AddEditUser";
import * as ROUTES from "utils/routes";
import { Link } from "react-router-dom";
import { SkeletonText, Breadcrumb, BreadcrumbItem } from "@carbon/ibm-security";

import {
  IManageReducerInitalState,
  ICustomerUserDetails,
  IManageContextProps,
} from "./UserInterfaces";
import { AuthContext } from "components/Auth";
import { handleTableSearch } from "utils/table";

const initialState: IManageReducerInitalState = {
  addEditViewEnabled: false,
  manageCustomerUsers: [] as ICustomerUserDetails[],
  manageCustomerUser: {} as ICustomerUserDetails,
  mode: "",
  tableDataLoaded: false,
};

export interface IUsersProps {
  claims: {};
  loaderState: false;
  handleCreateFlowView: (show: boolean, mode: string) => void;
}

const UserContainer: React.FunctionComponent<IUsersProps> = (props) => {
  const FirebaseCTX = React.useContext(FirebaseContext);
  const AuthCTX: any = props; //React.useContext(AuthContext);
  const [state, dispatch] = React.useReducer(UserReducer, initialState);
  // const [userRole, setUserRole] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  

  const handleCustomerUserSearch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchValue = event.target.value;
      handleTableSearch(searchValue, state.manageCustomerUsers, "name")
        .then((filteredResults: any) => {
          if (filteredResults.length) {
            dispatch({
              type: "manageCustomerUsers",
              value: filteredResults,
            });
          } else {
            initialLoad();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [state, dispatch]
  );

  const manageContextProps = {
    isLoading,
    handleCustomerUserSearch
  };

  const contextValue: IManageContextProps = React.useMemo(() => {
    return {
      ...manageContextProps,
      state,
      dispatch,
    };
  }, [state, dispatch, manageContextProps]);

  //Initial component mount
  const initialLoad = React.useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // setIsLoading(true);
    const manageCustomerUsers = [] as any;

    if (AuthCTX.claims && AuthCTX.claims.customerRef && FirebaseCTX) {
      FirebaseCTX.customerUsers()
        .doc(`${AuthCTX.claims.customerRef}`)
        .collection("users")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const tempObj = Object.assign({ id: doc.id }, doc.data());
            manageCustomerUsers.push(tempObj);
          });
          dispatch({
            type: "manageCustomerUsers",
            value: manageCustomerUsers,
          });
        });
    }
  }, [setIsLoading, dispatch, AuthCTX.claims]);

  React.useEffect(() => {
    const abortController = new AbortController();

    if (AuthCTX.claims) {
      initialLoad();
    }
    return () => abortController.abort();
  }, [AuthCTX]);

  // React.useMemo(() => {
  // 	if(AuthCTX.claims){
  // 		const { role, customerRef } = AuthCTX.claims
  // 		setUserRole(role)
  // 		setCustomerId(customerRef)
  // 	}
  // }, [setUserRole, userRole, AuthCTX.claims])

  const createNewUser = React.useCallback(() => {
    setIsLoading(true);

    if (FirebaseCTX) {
      const createCustomerUser =
        FirebaseCTX.functions.httpsCallable("createCustomerUser");
      const { manageCustomerUser } = state;

      const data = {
        name: manageCustomerUser.name,
        phone: manageCustomerUser.phone,
        email: manageCustomerUser.email,
        role: manageCustomerUser.role,
        customerRef: AuthCTX.claims.customerRef,
      };
      return createCustomerUser(data)
        .then(() => {
          initialLoad();
          return;
        })
        .catch((error) => {
          return Promise.reject(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    return;
  }, [AuthCTX.claims.customerRef, isLoading, state, dispatch]);

  const updateUser = React.useCallback(() => {
    setIsLoading(true);

    if (FirebaseCTX) {
      const updateCustomerUser =
        FirebaseCTX.functions.httpsCallable("updateCustomerUser");
      const { manageCustomerUser } = state;
      const id = manageCustomerUser.id;
      const originalCustomerDetails = state.manageCustomerUsers.find(
        (customer) => {
          return customer.id === id;
        }
      );

      const data = {
        name: manageCustomerUser.name,
        phone: manageCustomerUser.phone,
        email: manageCustomerUser.email,
        role: manageCustomerUser.role,
        originalCustomerDetails,
        customerRef: AuthCTX.claims.customerRef,
      };
      return updateCustomerUser(data)
        .then(() => {
          initialLoad();
          return;
        })
        .catch((error) => {
          return Promise.reject(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    return;
  }, [AuthCTX.claims.customerRef, state]);

  const deleteUser = React.useCallback(async (data) => {
    setIsLoading(true);
    const fetchUserId: any = FirebaseCTX?.functions.httpsCallable("fetchUserDetails");
    return fetchUserId(data).then((response) => {
        setIsLoading(false);
        return response;
      })
      .catch((error) => {
        console.log("error ", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <React.Fragment>
      <ManageContext.Provider value={contextValue}>
        <div className="bx--grid">
          <div className={"sc--main-title"}>
            {props.loaderState ? (
              <SkeletonText heading width={"350px"} />
            ) : (
              <React.Fragment>
                {state?.manageCustomerUser ? (
                  <React.Fragment>
                    <Breadcrumb
                      // onClick={this.handleBreadcrumb}
                      noTrailingSlash
                    >
                      <BreadcrumbItem>
                        <Link
                          onClick={() =>
                            props.handleCreateFlowView(false, "user-close")
                          }
                          to={`${ROUTES.ADMIN_CUSTOMERS}`}
                        >
                          Customers
                        </Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem
                        aria-current="page"
                        isCurrentPage
                        className="sc--current-page"
                      >
                        {`${props.claims?.customerName}`}
                      </BreadcrumbItem>
                    </Breadcrumb>
                  </React.Fragment>
                ) : (
                  <h1>Users</h1>
                )}
              </React.Fragment>
            )}

            <h1>Users</h1>
          </div>

          {state.addEditViewEnabled && (
            <AddEditUser {...{ createNewUser, updateUser, props }} />
          )}
          {!state.addEditViewEnabled && (
            <UserTable
              {...{
                ...props,
                deleteUser: deleteUser,
                
              }}
            />
          )}
        </div>
      </ManageContext.Provider>
    </React.Fragment>
  );
};

export default UserContainer;
