/* eslint-disable react/display-name */
/* eslint-disable no-useless-escape */
import React from 'react';
import { Modal } from '@carbon/ibm-security';
import { CreateFlowContainer } from 'components/CreateFlow';
import { Loader } from 'components/Loader';
import { Details, Location } from './Steps';
import CustomerContext from './ServiceLocationContext';
import { EMode } from './interface';
import { ICustomerLocation } from './interface';
import isEqual from 'lodash/isEqual';
import { RegexFormat, ECustomerFlowLabels } from 'utils/common';

export interface IServiceLocationAddEditState {
	customerOTPValidated: boolean;
	currentStep: number;
}

const addProgressStepLabels = Object.values(ECustomerFlowLabels);
const scPrefix = 'sc';

const ServiceLocationAddEdit: React.FunctionComponent = (props) => {
	const CustomerCTX = React.useContext(CustomerContext);
	const [ currentStep, setCurrentStep ] = React.useState<number>(0);
	const [ mainTitle, setMainTitle ] = React.useState<string>('');
	const [ isDisabled, setIsDisabled ] = React.useState<boolean>(true);
	const [ currentCustomer, setCurrentCustomer ] = React.useState<ICustomerLocation>({} as ICustomerLocation);
	const [ isModalOpen, setIsModalOpen ] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (CustomerCTX) {
			const mainTitle = CustomerCTX.state.mode === EMode.EDIT ? 'Edit service location' : 'Add service location';
			setMainTitle(mainTitle)
			setCurrentCustomer(CustomerCTX.state.customer);
		}
	}, [])

	const isLastStep = React.useCallback((): boolean => {
		return currentStep === addProgressStepLabels.length - 1;
	}, [ currentStep ]);

	const handleProgressStep = React.useCallback((step: number) => {
		if (step === currentStep) {
			setCurrentStep(step = step + 1);
		} else {
			setCurrentStep(step);
		}
	}, [ currentStep, setCurrentStep ]);

	const handleInput = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		switch (name) {
			case 'companyName':
			case 'contactName':
			case 'reference':
				CustomerCTX.dispatch({
					type: 'inputValidation',
					value: { [ name ]: { inValid: value === '' ? true : false } },
					step: addProgressStepLabels[ currentStep ] as string
				})
				break;
			case 'contactPhoneNumber':
				if (event.type === 'blur') {
					CustomerCTX.dispatch({
						type: 'inputValidation',
						value: { [ name ]: { inValid: value === '' || !RegexFormat.phoneNumber.test(value) ? true : false } },
						step: addProgressStepLabels[ currentStep ] as string
					});
				}
				break;
			case 'contactEmail':
				if (event.type === 'change') {
					CustomerCTX.dispatch({
						type: 'inputValidation',
						value: { [ name ]: { inValid: value === '' ? true : false } },
						step: addProgressStepLabels[ currentStep ] as string
					})
				} else {
					CustomerCTX.dispatch({
						type: 'inputValidation',
						value: { [ name ]: { inValid: !RegexFormat.email.test(value) ? true : false } },
						step: addProgressStepLabels[ currentStep ] as string
					})
				}
				break;
			case 'serviceContactEmail':
				if (event.type === 'blur') {
					CustomerCTX.dispatch({
						type: 'inputValidation',
						value: { [ name ]: { inValid: value !== '' && !RegexFormat.email.test(value) ? true : false } },
						step: addProgressStepLabels[ currentStep ] as string
					});
				}
				break;
			case 'serviceContactPhone':
				if (event.type === 'blur') {
					CustomerCTX.dispatch({
						type: 'inputValidation',
						value: { [ name ]: { inValid: value !== '' && !RegexFormat.phoneNumber.test(value) ? true : false } },
						step: addProgressStepLabels[ currentStep ] as string
					});
				}
				break;
			case 'addressLine1':
			case 'city':
				if (event.type === 'blur') {
					CustomerCTX.dispatch({
						type: 'inputValidation',
						value: { [ name ]: { inValid: value === '' ? true : false } },
						step: addProgressStepLabels[ currentStep ] as string
					});
				}
				break;
			default:
				break;
		}

		CustomerCTX.dispatch({
			type: 'customer',
			value: { [ event.target.name ]: event.target.value },
			isNested: event.target.getAttribute('data-attribute') === 'nested'
		});

	}, [ CustomerCTX.dispatch, currentStep ]);


	React.useEffect(() => {
		if (CustomerCTX.state.mode === EMode.ADD) {
			switch (addProgressStepLabels[ currentStep ]) {
				case ECustomerFlowLabels.DETAILS:
					if (
						CustomerCTX.state.customer.companyName === '' ||
						CustomerCTX.state.customer.contactName === '' ||
						!CustomerCTX.state.customer.contactEmail.match(RegexFormat.email) ||
						CustomerCTX.state.customer.contactPhoneNumber === '' ||
						!CustomerCTX.state.customer.contactPhoneNumber.match(RegexFormat.phoneNumber)
					) {
						setIsDisabled(true)
					} else {
						setIsDisabled(false)
					}
					break;
				case ECustomerFlowLabels.LOCATIONS:
					if (
						CustomerCTX.state.customer.address.addressLine1 === '' ||
						CustomerCTX.state.customer.address.city === '' ||
						CustomerCTX.state.customer.address.state === '' ||
						CustomerCTX.state.customer.address.postcode === '' ||
						CustomerCTX.state.customer.reference === ''
					) {
						setIsDisabled(true)
					} else {
						setIsDisabled(false)
					}
			}
		} else {
			if (isLastStep()) {
				setIsDisabled(isEqual(currentCustomer, CustomerCTX.state.customer))
			} else {
				setIsDisabled(false)
			}
		}

	}, [ setIsDisabled, CustomerCTX, currentStep, currentCustomer ])

	const closeFlowView = React.useCallback(() => {
		CustomerCTX.dispatch({
			type: 'addEditViewEnabled',
			value: {
				addEditViewEnabled: false,
				mode: ''
			}
		})
	}, [ CustomerCTX ]);

	const handleCreateFlowView = React.useCallback((): void => {
		if (!isEqual(currentCustomer, CustomerCTX.state.customer)) {
			setIsModalOpen(true);
		} else {
			closeFlowView();
		}
	}, [ setIsModalOpen, closeFlowView, currentCustomer ]);

	const renderDangerModal = (): JSX.Element => {
		return (
			<Modal
				className={`${scPrefix}--modal-danger`}
				danger={true}
				open={true}
				iconDescription={'Close the modal'}
				primaryButtonText={'Close without saving'}
				secondaryButtonText={'Continue editing'}
				modalHeading={'You have unsaved changes.'}
				shouldSubmitOnEnter={true}
				onRequestClose={() => setIsModalOpen(false)}
				onRequestSubmit={closeFlowView}
				onSecondarySubmit={() => setIsModalOpen(false)}
			>
				<div>
					<p className='sc--composed-modal--body'>{'If you\'d like to keep editing press the Continue editing button.'}</p>
				</div>
			</Modal>
		)
	}

	return (
		<React.Fragment>
			{CustomerCTX.isLoading && (<Loader message={CustomerCTX.state.mode === EMode.EDIT ? 'Updating customer details...' : 'Creating a new customer...'} />)}
			{isModalOpen && (renderDangerModal())}
			<CreateFlowContainer
				{...{
					title: mainTitle,
					currentStep: currentStep,
					handleProgressStep: handleProgressStep,
					handleSubmit: CustomerCTX.state.mode === EMode.EDIT ? CustomerCTX.updateCustomerLocation : CustomerCTX.createNewCustomerLocation,
					handleCreateFlowView: handleCreateFlowView,
					isLastStep: isLastStep,
					shouldEnableSave: isDisabled,
					mode: CustomerCTX.state.mode,
					stepsType: addProgressStepLabels,
					renderMain: () => (
						<React.Fragment>
							{currentStep === 0 && <Details handleInput={handleInput} data={props}/>}
							{currentStep === 1 && <Location handleInput={handleInput} data={props} />}
						</React.Fragment>
					)
				}}
			/>
		</React.Fragment>
	);
}

export {
	ServiceLocationAddEdit
}
