export const LANDING = '/';
export const LOGIN = '/login';
export const PASSWORD_RESET = '/password-reset';
export const ADMIN_ACTIVITY = '/activity';
export const ADMIN_DASHBOARD = '/dashboard';
export const ADMIN_CUSTOMERS = '/customers';
export const ADMIN_CONTRACTORS = '/contractors';
export const ADMIN_LEADS = '/leads';
export const CUSTOMERS_DETAILS_RECORDS = '/customers/users';

