/* eslint-disable no-undef */
interface IContractor {
	abn: string;
	address: IContractorAddress;
	companyName: string;
	contactEmail: string;
	contactName: string;
	contactPhoneNumber: string;
	id: string;
	serviceContactEmail: string;
	serviceContactName: string;
	isActive: boolean;
}

interface IContractorServicesAndLocations {
	contractorServices: string[];
	customerLocationId: string;
}

interface IContractorAddress {
	addressLine1: string;
	addressLine2?: string;
	city: string;
	country: string;
	postcode: number | string;
	state: string;
}

interface IContractorLocationDetails {
	address: IContractorAddress;
	billing: string;
	companyName: string;
	contactEmail: string;
	contactName: string;
	contactPhoneNumber: string;
	customer?: IContractorReference;
	geometry?: {
		location: ICoords;
	};
	reference: string;
}

interface ICoords {
	lat?: number | string;
	lng?: number | string;
}

interface IName {
	name: string;
}

interface IGoogleMarker {
	name: string;
	location: ICoords;
	reference: string;
}

interface ISelectedContractor extends IContractorLocationDetails, IName {}

interface IContractorReference {
	id: string;
	path: string;
}

interface IContractorCtx {
	details: IContractor;
}

enum EContractorServices {
	AIRCONDITIONING = 'Air Conditioning',
	CLEANING = 'Cleaning',
	ELECTRICAL = 'Electrical',
	GENERAL = 'General',
	PLUMBING = 'Plumbing',
	REFRIGERATION = 'Refrigeration',
}

enum ETableHeaderMap {
	NAME = 'Name',
	CONTACT = 'Contact',
	CUSTOMER = 'Customer',
	PHONE = 'Phone',
	EDIT = 'Edit',
	EMAIL = 'Email'
}

enum EFlowLabels {
	DETAILS = 'Details',
	LOCATIONS = 'Locations',
	MANAGE = 'Manage'
}

enum EMode {
	ADD = 'add',
	EDIT = 'edit'
}

interface ICustomerLocationContractors {
	contractorContactEmail?: string;
	contractorContactName?: string;
	contractorContactPhone?: string;
	contractorCompanyName?: string;
	contractorId?: string;
	contractorServices?: ICustomerLocationServices[];
	customerLocationId?: string | string[];
	customerId?: string;
	docRef?: string;
}


type ICustomerLocationServices = {
	[key in EContractorServices]: string;
}

export {
	EMode,
	EFlowLabels,
	ICustomerLocationServices,
	ISelectedContractor,
	IGoogleMarker,
	ICoords,
	IContractor,
	IContractorCtx as IContractorBase,
	IContractorLocationDetails,
	ICustomerLocationContractors,
	ETableHeaderMap,
	EContractorServices,
	IContractorServicesAndLocations
};
